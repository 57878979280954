import * as React from 'react';
import Layout from '../components/Layout';

function NotFound() {
  return (
      <Layout>
      <div id="404">
        Hmmm...we can't find that.
      </div>
      </Layout>
  );
}
export default NotFound;
