import React, { Component } from 'react';
import './BackToMap.css';

class BackToMap extends Component {
  render() {
    return (
      <a href="/guide/map" className="group flex items-center">
        <span className="inline-block px-6 text-3xl text-pewter uppercase group-hover:text-sunny">
          back to map
        </span>
        {/* <img
          src={mapIcon}
          alt="'Back to map' icon"
          className="inline-block "
        /> */}
        <svg viewBox="0 0 85 85" width="85" height="85" className="">
          <g
            id="WbeFGi.tif_7_"
            transform="matrix(0.188296, 0, 0, 0.188296, -5.233222, -5.806509)"
          >
            <g>
              <path
                className="path transform"
                style={{ fill: `#F09757` }}
                d="M448.4,282.4c-3,20.1-8.3,39.5-16.9,58c-3.8,8.1-8.3,15.8-13,23.4c-1,1.6-2.2,2.4-4.1,2.5 c-5.9,0.6-11.7,1.2-17.6,1.9c-2.9,0.4-4-0.6-3.8-3.6c1.2-14.8,2.2-29.6,3.3-44.3c0.9-11.4,1.8-22.8,2.6-34.3 c1.6-22.2,3.3-44.3,4.9-66.5c1.1-15.3,2.1-30.6,3.2-45.9c0.7-10.8,1.5-21.5,2.2-32.3c0.1-0.9-0.3-2.3,1-2.6 c0.8-0.2,1.4,0.8,1.8,1.5c20.2,28.7,32.9,60.2,36.5,95.2c0.2,4.1-0.9,8.2,1,12.2c0,6.1,0,12.2,0,18.2c-1.8,2.9-0.8,6.1-0.9,9.2 C448.9,277.7,447.8,280,448.4,282.4z"
              />
              <path
                className="path transform"
                style={{ fill: `#F4C2A3` }}
                d="M262.3,455.4c-7.1,0-14.2,0-21.3,0c0-0.3,0-0.6-0.1-0.9c7.1,0,14.3,0,21.4,0 C262.3,454.8,262.3,455.1,262.3,455.4z"
              />
              <path
                className="path transform"
                style={{ fill: `#F4C2A3` }}
                d="M449.7,247.8c-1.9-3.9-0.7-8.1-1-12.2c0.3,0.1,0.7,0.1,1,0C449.7,239.7,449.7,243.8,449.7,247.8z"
              />
              <path
                className="path transform"
                style={{ fill: `#FAE6D9` }}
                d="M448.4,282.4c-0.5-2.4,0.6-4.7,0.4-7.1c0.3,0,0.6-0.1,0.9-0.1c0,2.4,0,4.7,0,7.1 C449.2,282.3,448.8,282.4,448.4,282.4z"
              />
              <path
                className="path transform"
                style={{ fill: `#F4C2A3` }}
                d="M449.7,275.2c-0.3,0-0.6,0.1-0.9,0.1c0.1-3.1-0.9-6.3,0.9-9.2C449.7,269.1,449.7,272.1,449.7,275.2 z"
              />
              <path
                className="path transform"
                style={{ fill: `#FAE6D9` }}
                d="M241,454.5c0.1,0.3,0.1,0.6,0.1,0.9c-2.4,0-4.7,0-7.1,0c0-0.3,0-0.6,0-0.9 C236.3,454.5,238.6,454.5,241,454.5z"
              />
              <path
                className="path transform"
                style={{ fill: `#FAE6D9` }}
                d="M262.3,455.4c0-0.3,0-0.6,0.1-0.9c2.3,0,4.7,0,7,0c0,0.3,0,0.6,0,0.9 C267,455.4,264.7,455.4,262.3,455.4z"
              />
              <path
                className="path transform"
                style={{ fill: `#EFA577` }}
                d="M242.9,278.7c-27.8-21.4-55.6-42.7-83.3-64.1c-8.1-6.3-8.2-6.3-2.5-15.2 c19.4-30.2,38.8-60.4,58.3-90.6c9.8-15.2,19.7-30.2,29.3-45.5c2.2-3.5,4.6-5.1,8.9-5c37.5,1.1,72.4,11,104.2,31.2 c13.6,8.6,25.9,18.8,37.2,30.2c2.4,2.3,3.4,4.7,3.2,8c-1.4,20.8-2.7,41.7-4,62.5c-0.2,4-0.5,8.1-0.4,12.1c0.1,3.6-1.5,5.4-4.7,6 c-6,1-11.9,2.5-17.9,2.7c-11.3,0.3-22.5,2.4-33.8,2.3c-3.9,0-7.4,1.6-10.4,4.5c-13.5,13.2-27.2,26.2-39.5,40.5 c-1.7,1.9-3.5,3.7-4.3,6.2c-0.8,0.4-1.5,0.9-1.7,1.8c-10.1-2.6-19.6-1.4-28.8,3.8C248.9,272.5,246.5,276.2,242.9,278.7z"
              />
              <path
                className="path transform"
                style={{ fill: `#F09757` }}
                d="M252.1,331.7c-4.6,15.3-9.6,30.5-13.8,45.9c-1.5,5.6-4.8,7.4-9.9,8.2c-7.4,1.2-14.8,1.1-22.2,1.5 c-18.3,1.1-36.7,1.7-55.1,2.5c-13.3,0.6-26.6,1.2-39.9,2c-3.3,0.2-5.6-0.7-7.9-3.3c-26.2-30.1-42.2-64.7-47.9-104.2 c-5.3-36.6-0.1-71.9,14.2-105.9c2.8-6.5,2.1-7,8.4-2.3c45.6,34.1,91.4,68.1,137,102.3c8.2,6.2,16.6,12.2,24.8,18.2 c-0.1,4.9,1.3,9.4,3.1,14C245.9,317.8,250.2,324.2,252.1,331.7z"
              />
              <path
                className="path transform"
                style={{ fill: `#E37D3A` }}
                d="M291.2,274.1c10.7-13.5,22.2-26.2,33.8-38.9c1.3-1.4,2.8-1.8,4.4-2.1c17.7-3.5,35.6-5.9,53.4-8.9 c3.3-0.6,3.2,1.2,3.2,3.4c-0.1,11.8-1.6,23.5-2.7,35.3c-2.1,21.6-4.4,43.2-6.7,64.8c-1.2,11.6-2.7,23.1-3.8,34.7 c-0.3,3.3-1.9,4.4-4.7,4.8c-16.1,2.1-32.3,4.2-48.4,6.4c-14,1.9-27.9,3.8-41.9,5.7c-8.3,1.1-16.6,2.3-24.9,3.3 c-5.3,0.6-7.3-2-5.2-7c4.2-10.3,8.2-20.7,11.7-31.2c4.9,5.8,7,5.7,11-1c7.5-12.6,13.5-25.9,20.2-38.9c3.5-6.9,5.1-14.6,3-22.5 C292.9,279.2,292,276.6,291.2,274.1z"
              />
              <path
                className="path transform"
                style={{ fill: `#EFA577` }}
                d="M269.4,454.5c-2.3,0-4.7,0-7,0c-7.1,0-14.3,0-21.4,0c-2.3,0-4.7,0-7,0c-34.2-3.5-65.6-14.4-94-34 c-3.9-2.7-7.7-5.5-11.5-8.3c-0.7-0.5-1.6-1.1-1.3-2c0.3-0.6,1.3-1,2.1-1.1c4.2-0.5,8.4-0.9,12.6-1.2c17.1-1.2,34.3-2.1,51.4-3.4 c18.9-1.4,37.9-3.2,56.8-4.7c12.7-1,25.5-1.5,38.3-2.4c14.6-1.1,29.2-2.4,43.7-3.6c5.4-0.4,10.7-0.7,16.1-1 c14.9-1,29.9-2,44.8-3.2c0.8-0.1,1.7-0.1,2.5-0.1c0.8,0,1.8-0.3,2.1,0.8c0.2,0.7-0.3,1.3-0.7,1.7c-0.7,0.7-1.4,1.5-2.1,2.2 c-27.9,28.3-61.1,47.3-99.9,56.1C286.4,452.3,278,453.6,269.4,454.5z"
              />
              <path
                className="path transform"
                style={{ fill: `#E37D3A` }}
                d="M198.6,94.2c-0.3,1.9-1.3,3.4-2.3,4.9c-12.8,19.1-25.6,38.1-38.4,57.1c-7,10.3-13.9,20.8-21.2,30.8 c-6.3,8.6-6.3,8.5-13.9,1.5c-11.5-10.6-23.1-21.2-34.8-31.7c-4.6-4.2-4.9-4.3-1.5-9.4c14.8-22.3,33.3-41.1,55.8-55.7 c9.8-6.3,19.9-12,30.6-16.7c3-1.4,5.1-1.2,7.6,1.2c4.9,4.8,10.3,9.2,15.4,13.9C197.1,91.1,198.6,92.2,198.6,94.2z"
              />
              <path
                className="path transform"
                style={{ fill: `#F09757` }}
                d="M207,79.7c-1.7-0.1-3.4-0.7-4.9-1.9c-3.2-2.5-6.4-4.9-9.6-7.3c-1.9-1.4-2.3-2.3,0.4-3.1 c9-2.8,18.2-4.7,27.4-6.4c1.5-0.3,2.6,0,1.4,1.7c-3.6,5.2-7.3,10.4-11,15.6C209.9,79.5,208.7,80,207,79.7z"
              />
              <path
                className="path transform"
                style={{ fill: `#FAE6D9` }}
                d="M284.6,270c-1.5-0.7-2.7-1.7-3-3.5c0.2-1,0.8-1.5,1.7-1.8C283.7,266.5,284.8,268.1,284.6,270z"
              />
              <path
                className="path transform"
                style={{ fill: `#EFA577` }}
                d="M268.6,283.2c7.1-0.9,11.6,5.4,11.8,11.6c0.3,6.3-6.1,11.6-13.4,11.7c-6.9,0.1-11.8-4.4-11.8-11 C255.1,287.9,260.2,283.2,268.6,283.2z"
              />
              <path
                id="arrow"
                style={{ fill: `#CFD8D9` }}
                className="path transform"
                d="M291.2,274.1c0.8,2.6,1.6,5.1,2.3,7.7c2.1,7.9,0.5,15.6-3,22.5c-6.7,13-12.7,26.3-20.2,38.9 c-4,6.7-6,6.9-11,1c-3.2-3.7-4-8.8-7.3-12.5c-1.9-7.5-6.2-14-9-21.1c-1.8-4.6-3.3-9.1-3.1-14c-0.7-6.3,1.2-12.1,3-18 c3.6-2.5,6-6.2,9.9-8.4c9.1-5.2,18.7-6.4,28.8-3.8c0.2,1.8,1.5,2.8,3,3.5C287.6,270,288.4,273.8,291.2,274.1z M268.6,283.2 c-8.4,0-13.5,4.7-13.4,12.4c0,6.5,4.9,11,11.8,11c7.3-0.1,13.6-5.4,13.4-11.7C280.1,288.6,275.7,282.3,268.6,283.2z"
              />
            </g>
          </g>
        </svg>
        {` `}
      </a>
    );
  }
}

export default BackToMap;
